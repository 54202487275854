import logo from "./logo.svg";
import "./App.css";
import { AiFillLinkedin, AiFillFacebook, AiFillTwitterSquare } from "react-icons/ai";

function App() {
   return (
      <div className="App">
         <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>Coming Soon</p>
            <p>
               Contact Us:
               <br/>
               <a className="a-link" href="mailto:info@scryber.dev">
                  info@scryber.dev
               </a>
            </p>

            <div>
               <AiFillLinkedin onClick={() => window.location.href=`https://www.linkedin.com/company/scryber-development-ltd`}/>
               <AiFillFacebook onClick={() => window.location.href=`https://www.facebook.com/ScryberDevelopment`}/>
               <AiFillTwitterSquare onClick={() => window.location.href=`https://twitter.com/scryberltd`}/>
            </div>
         </header>
      </div>
   );
}

export default App;
